.fieldWrapper {
  width: 200px;
  margin: auto;
  text-align: left;
  padding-bottom: 5px;
}

.controlButton {
  padding: 10px 20px;
  cursor: pointer;
  color: #222;
}

.labelText {
}

.disclaimer {
  max-width: 600px;
  margin: auto;
  text-align: center;
}
